import { CustomButton } from 'components'
import React from 'react'
import { formatCurrency } from 'utils'

const PartItem = ({ selected, handleSelected, sparepart, selectedData }) => {
  function handleChangeQty(qty) {
    let newObj = {}
    if (selectedData !== undefined) {
      newObj = {
        ...selectedData,
        quantity: qty
      }
    } else {
      newObj = {
        ...sparepart,
        quantity: qty
      }
    }
    handleSelected(newObj)
  }
  return (
    <li
      onClick={() => {
        selected ? null : handleSelected(sparepart)
      }}
      className={`flex gap-2 rounded-[4px] relative border border-solid cursor-pointer p-4 ${
        selected ? 'border-primary-300 bg-primary-50' : ' bg-white border-gray-200'
      }`}>
      {/* <img src={SampleOil} className="w-8 object-contain md:w-14" alt="oli" /> */}
      <div className="grid grid-flow-col w-full">
        <div className="flex flex-col gap-2">
          <span className="text-sm-medium md:text-md-medium text-gray-900">{sparepart?.name}</span>
          <span className="text-lg-medium md:display-xs-medium text-gray-700">
            {sparepart?.price == 0
              ? formatCurrency(sparepart?.price_total ?? 0)
              : formatCurrency(
                  selected
                    ? selectedData?.price * selectedData?.quantity + selectedData?.service_price
                    : sparepart?.price * sparepart?.quantity + sparepart?.service_price ?? 0
                )}{' '}
          </span>
        </div>
        {!sparepart?.name?.toLowerCase()?.includes('layanan jasa') && selected ? (
          <div className="flex justify-end items-center gap-3">
            <CustomButton
              disabled={parseInt(selectedData?.quantity) - 1 < 1}
              onClick={(e) => {
                e.preventDefault()
                handleChangeQty(parseInt(selectedData?.quantity) - 1)
              }}>
              <span
                className={`display-xs-semibold ${
                  parseInt(selectedData?.quantity) - 1 < 1 ? 'text-gray-500' : 'text-white'
                }`}>
                -
              </span>
            </CustomButton>
            <div>
              <input
                type="number"
                onKeyDown={(evt) =>
                  ['e', 'E', '+', '-', ','].includes(evt.key) && evt.preventDefault()
                }
                max={2147483647}
                maxLength={10}
                minLength={1}
                min={1}
                onWheel={() => document.activeElement.blur()}
                className="w-[40px] px-2 py-1 rounded-md"
                onChange={(e) => {
                  e.preventDefault()
                  if (e.target.value == '' || e.target.value == '0') return
                  handleChangeQty(e.target.value)
                }}
                value={selectedData?.quantity}
              />
            </div>
            <CustomButton
              onClick={(e) => {
                e.preventDefault()
                handleChangeQty(parseInt(selectedData?.quantity ?? sparepart?.quantity) + 1)
              }}>
              <span className="text-xl-semibold text-white">+</span>
            </CustomButton>
          </div>
        ) : null}
      </div>
      {/* {sparepart?.is_recommended ? (
        <BadgeItem className="absolute right-3">
          <ThumbsUpIcon className={'lg:hidden block w-3 h-3 md:w-5 md:h-5 stroke-warning-700'} />
          <p className="hidden lg:block">Recommendation</p>
        </BadgeItem>
      ) : null} */}
    </li>
  )
}

export default PartItem
